import ucenter_request from '@/utils/ucenterrequest.js'

// 在微信小程序里的授权登录
export function loginWechatUser(params) {
  return ucenter_request({
    url: '/api/miniprogram/getUserInfo',
    method: 'POST',
    params
  })
}

// 获取群二维码
export function getWxworkGroupCode(params) {
  return ucenter_request({
    url: '/api/wxwork/getWxworkGroupCode',
    method: 'POST',
    params
  })
}
// 获取地区群二维码
export function getAreaQrcodeForChatGroup(params) {
  return ucenter_request({
    url: '/api/wxwork/getAreaQrcodeForChatGroup',
    method: 'POST',
    params
  })
}
// 获取地区二维码
export function getAddressCode(params) {
  return ucenter_request({
    url: '/api/wxwork/getAddressCode',
    method: 'POST',
    params
  })
}
// 获取兴趣二维码
export function getInterestCode(params) {
  return ucenter_request({
    url: '/api/wxwork/getInterestCode',
    method: 'POST',
    params
  })
}
