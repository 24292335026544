<template>
  <div>
  </div>
</template>

<script>
import { loginWechatUser } from '@/api/weixin.js'
import Cookie from 'js-cookie'

export default {
  data() {
    return {
      token: this.$route.query.token || '',
      uuid: this.$route.query.uuid || '',
      app_code: this.$route.query.app_code || '',
    }
  },
  created() {
    this.wxLogin(this.token, this.uuid, this.app_code)
  },
  methods: {
    wxLogin(access_token, uuid, app_code) {
      let info = {
        app_code: app_code,
        access_token: access_token,
        uuid: uuid
      }
      loginWechatUser(info).then((res) => {
        let errNo = res.errNo
        if (errNo === 0) {
          let result = res.result
          let username = result.username
          console.log('登录成功 ', username);
        }
        this.$router.go(-1)
      }).catch((err) => {
        this.$router.go(-1)
      })
    },
  },
}
</script>
<style lang="scss" scoped>
</style>
